import { memo, type ReactNode } from 'react'
import { NoOrusOrPartnerAgents } from '../../templates/no-orus-or-partner-agents'
import { Page } from '../../templates/page'

/**
 * Wrapper page to use for all subscription pages
 */
export const SubscriptionPageV2 = memo<{ children: ReactNode }>(function SubscriptionPageV2({ children }) {
  return (
    <NoOrusOrPartnerAgents>
      <Page>{children}</Page>
    </NoOrusOrPartnerAgents>
  )
})
